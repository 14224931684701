import BaseCrudService from "./baseCrud.service";
import axios from 'axios';
import Cep from 'cep-promise'

export default class LocationService extends BaseCrudService {
    constructor() {
        super("Location");
    }

    listAllState(callback) {
        this.getRequest("ListAllState").then(callback);
    }

    // searchCEP(callback, cep) {
    //     return axios.get( 'https://viacep.com.br/ws/' + cep + '/json').then(callback);
    // }

    searchCEP(cep){
        return Cep(cep);
    }
}