<template>
  <div>
    <Modal
      :cardTitle="true"
      :withClose="false"
      modalTitle="Confirmação cadastral"
      myMaxWidth="640px"
      :modalFooter="true"
      v-model="value"
    >
      <!-- body  -->
      <template>
        <v-container fluid pa-0>
          <div class="content-cart">
            <div class="container">
              <!-- <div class="content-top">
                <div class="image">
                  <img src="../assets/images/EmptyTasks@3x.png" />
                </div>
                <div class="label">
                  <h2 class="text-dark">
                    Seu pedido de doação nº
                    <span class="text-blue-light">{{cartId}}</span>
                  </h2>
                  <p class="f-size-16">
                    no valor total de
                    <strong class="text-red">{{totalValue | FormatMoney}}</strong> foi recebido com sucesso.
                  </p>
                  <p class="text-orange">
                    <v-icon class="mr-2 text-orange">fas fa-exclamation-triangle</v-icon>
                    <b>Sua doação ficará pendente até a confirmação do pagamento.</b>
                  </p>
                </div>
              </div> -->
              <h2 class="text-dark">Complete ou confirme seus dados</h2>
              <hr />
              <div>
                <p class="mt-2 mw-half">
                  <strong
                    >Confirme abaixo seus dados para que seus comprovantes sejam
                    enviados corretamente:</strong
                  >
                </p>
                <v-layout row wrap align-start mw-half>
                  <v-flex xs12 md12>
                    <InputText
                      ref="emailInput"
                      required="true"
                      type="email"
                      textLabel="E-mail"
                      v-model="person.email"
                      place-holder="E-mail"
                      @blur="validate('email')"
                    />
                  </v-flex>
                  <label class="d-flex justify-end msg-validation" v-if="!emailIsValid">Por favor, insira um e-mail válido.</label>
                  <v-flex v-if="campaign && campaign.campaignInputInformation.modalConfirmationInformPhone" xs12 md12>
                    <InputText
                      ref="phoneInput"
                      type="tel"
                      required="true"
                      textLabel="Telefone"
                      place-holder="(00) 00000-0000"
                      v-model="person.phone"
                      :mask="['(##) ####-####', '(##) #####-####']"
                      autocomplete="tel"
                      invalidInputMessage="Preencha o telefone"
                    />
                  </v-flex>
                  <v-flex v-if="campaign && campaign.campaignInputInformation.modalConfirmationInformAlternativePhone" xs12 md12>
                    <InputText
                      ref="alternativePhoneInput"
                      type="tel"
                      textLabel="Telefone alternativo"
                      place-holder="(00) 00000-0000"
                      v-model="person.alternativePhone"
                      :mask="['(##) ####-####', '(##) #####-####']"
                      autocomplete="tel"
                      invalidInputMessage="Preencha o telefone"
                    />
                  </v-flex>
                  <v-flex v-if="campaign && campaign.campaignInputInformation.modalConfirmationInformCompany" xs12 md12>
                    <InputText
                      ref="companyNameInput"
                      type="text"
                      textLabel="Nome da empresa"
                      v-model="person.companyName"
                      place-holder="Nome da empresa"
                    />
                  </v-flex>
                  <v-flex v-if="campaign && campaign.campaignInputInformation.modalConfirmationInformDepartment" xs12 md12>
                    <InputText
                      ref="departamentNameInput"
                      type="text"
                      textLabel="Nome do departamento"
                      v-model="person.departamentName"
                      place-holder="Nome do departamento"
                    />
                  </v-flex>
                  <div v-if="campaign && campaign.campaignInputInformation.modalConfirmationInformAddress">
                    <v-flex xs12 md12>
                      <InputText
                        ref="postalCodeInput"
                        required="true"
                        type="tel"
                        textLabel="CEP Residencial"
                        place-holder="00000-000"
                        autocomplete="billing postal-code"
                        v-model="person.address.postalCode"
                        mask="#####-###"
                        @input="searchCEP()"
                        :validation="() => {return this.person.address.postalCode.length == 8}"
                        invalidInputMessage="Preencha o CEP"
                      />
                    </v-flex>
                    <!--  -->
                    <div
                      v-if="person.address.postalCode.length > 0"
                      class="mw-half layout row wrap align-start justify-space-between row-modal"
                    >
                      <v-flex xs12 md8>
                        <InputText
                          ref="streetInput"
                          type="text"
                          required="true"
                          textLabel="Logradouro"
                          autocomplete="billing street-address"
                          place-holder="ex: Av. Paulista"
                          v-model="person.address.street"
                          :labelCharLimit="200"
                          invalidInputMessage="Preencha o logradouro"
                        />
                      </v-flex>
                      <v-flex xs12 md4>
                        <InputText
                          ref="numberInput"
                          required="true"
                          type="number"
                          textLabel="Número"
                          autocomplete="billing address-level2"
                          place-holder="ex: 123"
                          v-model="person.address.number"
                          invalidInputMessage="Preencha o número"
                        />
                      </v-flex>
                      <v-flex xs12 md6>
                        <InputText
                          type="text"
                          textLabel="Complemento"
                          autocomplete="billing address-level1"
                          place-holder="ex: Casa B"
                          v-model="person.address.complement"
                          :labelCharLimit="50"
                        />
                      </v-flex>
                      <v-flex xs12 md6>
                        <InputText
                          ref="neighborhoodInput"
                          type="text"
                          required="true"
                          textLabel="Bairro"
                          place-holder="ex: Jardim Paulista"
                          v-model="person.address.neighborhood"
                          :labelCharLimit="100"
                          invalidInputMessage="Preencha o bairro"
                        />
                      </v-flex>
                      <v-flex xs12 md6 @keyup="onlyLetters()">
                        <InputText
                          ref="cityInput"
                          type="text"
                          required="true"
                          textLabel="Cidade"
                          autocomplete="city"
                          v-model="person.address.city"
                          :disabled="addressDisable.city"
                          :labelCharLimit="100"
                        />
                      </v-flex>
                      <v-flex v-if="campaign && campaign.campaignInputInformation.modalConfirmationInformState" xs12 md6>
                      <InputSelect
                        ref="stateInput"
                        textLabel="UF"
                        :items="stateItems"
                        v-model="person.address.state"
                        :disabled="addressDisable.state"
                        valueAttribute="name"
                        textAttribute="name"
                      ></InputSelect>
                    </v-flex>
                    </div>
                  </div>
                  <div class="mw-half layout row wrap align-start justify-space-between row-modal">
                    <v-flex v-if="campaign && campaign.campaignInputInformation.modalConfirmationInformGender" xs12 md6>
                      <InputSelect
                        textLabel="Gênero"
                        ref="genderInput"
                        required="true"
                        :items="gender"
                        v-model="person.gender"
                        textAttribute="text"
                        valueAttribute="value"
                        invalidInputMessage="Informe o gênero"
                      ></InputSelect>
                    </v-flex>
                    <v-flex v-if="campaign && campaign.campaignInputInformation.modalConfirmationInformBirthDate" xs12 md6>
                    <InputDate
                      :top="true"
                      ref="birthDateInput"
                      required="true"
                      textLabel="Data de Nascimento"
                      placeHolder="__/__/____"
                      valueAttribute="name"
                      textAttribute="name"
                      v-model="person.birthDate"
                      @input="validate('birthDate')"
                    ></InputDate>
                    <label class="d-flex justify-end msg-validation" v-if="!dateIsValid">Por favor, insira uma data válida.</label>
                  </v-flex>
                  </div>
                  <v-flex v-if="campaign && campaign.campaignInputInformation.modalConfirmationInformMemberCode" xs12 md12>
                    <InputText
                      type="text"
                      textLabel="Código de indicação (opcional)"
                      v-model="person.registrationWithMemberCode"
                      place-holder="Se você possui um código de indicação digite aqui"
                      @blur="validateMemberCode"
                    />
                  </v-flex>
                  <v-flex xs12 md12>
                    <p style="font-style: italic" v-if="campaign && campaign.campaignInputInformation.modalConfirmationInformMemberCode && personIndicationName">Indicado por: {{ personIndicationName }}</p>
                  </v-flex>                  
                  <v-checkbox
                    v-model="dataOk"
                    label="Ok, os meus dados estão corretos"
                  ></v-checkbox>
                </v-layout>
              </div>
            </div>
          </div>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-container fluid py-2>
          <v-layout>
            <v-flex text-xs-right>
              <v-btn
                flat
                round
                color="white"
                class="btn-green px-5 text-uppercase"
                large
                :disabled="!dataOk || !dateIsValid || !emailIsValid"
                @click="close"
                >Continuar</v-btn
              >
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </Modal>
  </div>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
import LocationService from "@/scripts/services/location.service.js";
import PersonService from "@/scripts/services/person.service.js";
import CampaignService from "@/scripts/services/campaign.service.js";
import Person from "@/scripts/models/person.model";
import Campaign from "@/scripts/models/campaign.model";
import Address from "@/scripts/models/address.model";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import CommonHelper from "@/scripts/helpers/common.helper";
import { mapGetters, mapState } from "vuex";

export default {
  extends: ValidatableComponent,
  components: {
    Modal,
  },
  data() {
    return {
      campaign: new Campaign(),
      person: new Person(),
      locationService: new LocationService(),
      personService: new PersonService(),
      campaignService: new CampaignService(),
      cepValidation: true,
      stateItems: [],
      totalValue: 0,
      cartId: 0,
      addressDisable: {
        city: true,
        state: true,
      },
      gender:[{text: "Feminino", value:"F"}, {text: "Masculino", value:"M"}, {text: "Outros", value:"O"}, {text: "Não informar", value:"-"}],
      CommonHelper: CommonHelper,
      dataOk: false,
      dateIsValid: true,
      emailIsValid: true,
      memberCodeIsValid: null,
      personIndicationName: null
    };
  },
  watch: {
    authenticated(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.locationService.listAllState(this.listAllStateCallback);
        this.loadPerson();
      }
    },
  },
  created() {
    this.campaignService.findBySubdomain((data) => {
      this.campaign = data;
      if (this.campaign && this.campaign.informState && !this.campaign.informAddress)
        this.addressDisable.state = false;
    });
  },
  computed: mapState(["authenticated"]),
  methods: {
    close() {
      if (!this.cepValidation) {
        CommonHelper.swal("CEP inválido");
      } else if (!this.dateIsValid) {
        CommonHelper.swal("Data de nascimento inválida.");
      }
      else if (this.isValid()) {
        this.personService.saveProfile(() => {
          this.show = false;
        }, this.person);
        this.$emit("close");
      }
    },
    validateMemberCode(){
      if(this.person.registrationWithMemberCode == ''){
        this.memberCodeIsValid = null
        return;
      }
      this.personService.findPersonByCodeIndication(this.person.registrationWithMemberCode).then((data) => {
        this.personIndicationName = null
        if(data) {
          this.memberCodeIsValid = true
          this.personIndicationName = data
        }
        else {
          this.memberCodeIsValid = false
          CommonHelper.swal('Código de indicação inválido');
        }
      }).catch((error) => {
        this.memberCodeIsValid = false
          CommonHelper.swal('Código de indicação inválido');
      })
    },
    listAllStateCallback(data) {
      this.stateItems = data;
    },
    searchCEP() {
      if (this.person.address.postalCode.length == 8) {
        this.$store.commit("SET_LOADER", true);
        this.locationService
          .searchCEP(this.person.address.postalCode)
          .then(data => {
            this.searchCEPCallback(data);
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    searchCEPCallback(obj) {
      if (obj && !obj.erro) {
        this.person.address.street = obj.street;
        this.person.address.neighborhood = obj.neighborhood;
        this.person.address.city = obj.city;
        this.person.address.state = obj.state;
        this.cepValidation = true;
        this.addressDisable.city = true;
        this.addressDisable.state = true;
      } else {
        this.person.address.street = "";
        this.person.address.neighborhood = "";
        this.person.address.city = "";
        this.person.address.state = "";
        this.cepValidation = false;
        this.addressDisable.city = false;
        this.addressDisable.state = false;
      }
      this.$store.commit("SET_LOADER", false);
    },
    loadPerson() {
      this.personService.getLoggedPersonAddress().then(
        function (data) {          
          let response = data;
          if (!data) return;
          if (response.address == null) {
            response.address = new Address();
          }
          this.person = response;
          //this.person.registrationWithMemberCode = "";
          this.person.address.complement = this.person.address.complement || "";
          this.person.address.postalCode = this.person.address.postalCode || "";
          this.person.address.street = this.person.address.street || "";
          this.person.address.neighborhood =
            this.person.address.neighborhood || "";
          this.person.address.city = this.person.address.city || "";
          this.person.address.state = this.person.address.state || "";
          if(this.person.registrationWithMemberCode) this.validateMemberCode();
        }.bind(this)
      );
    },
    validate() {
      
      if (this.campaign.campaignInputInformation.modalConfirmationInformBirthDate) {
        this.dateIsValid = this.CommonHelper.getAge(this.person['birthDate']) > 1;
      }

      this.emailIsValid = this.CommonHelper.validateEmail(this.person['email']);

      if (this.dateIsValid && this.emailIsValid) {
        this.dataOk = true; 
      }
      else{
        this.dataOk = false;
      }
    },
    onlyLetters(){
      this.person.address.city = this.person.address.city.replace('0', '')
                                                         .replace('1', '')
                                                         .replace('2', '')
                                                         .replace('3', '')
                                                         .replace('4', '')
                                                         .replace('5', '')
                                                         .replace('6', '')
                                                         .replace('7', '')
                                                         .replace('8', '')
                                                         .replace('9', '');
    }
  },
};
</script>